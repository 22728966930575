.search-container {
  .icon {
    cursor: pointer;
  }
}
#search_block_top {
  padding-top: 100px;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0,0,0,0.9);
  z-index: 999;
  display: none;

  .search_block_top__close-btn {
    position: absolute;
    top: 37px;
    right: 37px;
    height: 40px;
    width: 40px;
    cursor: pointer;

    &:before, &:after {
      content: "";
      position: absolute;
      background: white;
      right: -5px;
      top: 50%;
      height: 3px;
      width: 50px;
      -webkit-transform: rotate(45deg);
      -moz-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      -o-transform: rotate(45deg);
      transform: rotate(45deg);
    }
    &:after {
      -webkit-transform: rotate(-45deg);
      -moz-transform: rotate(-45deg);
      -ms-transform: rotate(-45deg);
      -o-transform: rotate(-45deg);
      transform: rotate(-45deg);
    }
  }

  .icon{
    height: 80px;
    width: 80px;
    float: left;
    cursor: pointer;
  }
  form {
    float: left;
    margin-left: 20px;
    width: calc(100% - 120px);

    .search_query {
      height: 60px;
      margin-top: 10px;
      background: none;
      border: none;
      font-size: 50px;
      color: white;
      padding: 0;
      float: left;
      width: 60%;

      &:focus {
        outline: none;
        box-shadow: none;
      }
    }
    .button-search {
      opacity: 0;
      float: left;
    }
  }

  @media (max-width: 450px) {
    .icon{
      height: 40px;
      width: 40px;
      float: left;
      cursor: pointer;
    }

    form {
      width: calc(100% - 80px);

      .search_query {
        height: 30px;
        margin-top: 5px;
        font-size: 25px;
      }
    }
  }
}